/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { SupportAgent } from "@mui/icons-material";

import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { useAuth } from "../../../../../app/contexts/AuthContext";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const { user, partnersToken } = useAuth();

  const getMenuItemActive = (url, hasSubmenu = false, avoid = []) => {
    return checkIsActive(location, url, avoid)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  const hasPermission = permissionName => {
    return user?.permissions?.indexOf(permissionName) !== -1;
  };

  const tmpAnalyticsPageUrl = `https://analytics.exegestaoempresarial.com.br/bi/dashboard?token=${partnersToken}`;

  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {hasPermission("home-page") && (
          <li className={`menu-item ${getMenuItemActive("/home")}`}>
            <NavLink className="menu-link" to="/home">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Home.svg")} />
              </span>
              <span className="menu-text">Home</span>
            </NavLink>
          </li>
        )}
        {hasPermission("documents-page") && (
          <li className={`menu-item ${getMenuItemActive("/ecm")}`}>
            <NavLink className="menu-link" to="/ecm">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/General/Folder.svg")}
                />
              </span>
              <span className="menu-text">Documentos</span>
            </NavLink>
          </li>
        )}
        {hasPermission("task-center-page") && (
          <li className={`menu-item ${getMenuItemActive("/bpm/task-center")}`}>
            <NavLink className="menu-link" to="/bpm/task-center">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/General/Task_Center.svg",
                  )}
                />
              </span>
              <span className="menu-text">Central de tarefas</span>
            </NavLink>
          </li>
        )}
        {hasPermission("task-center-page") && (
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/bpm",
              true,
              ["/bpm/task-center"],
            )}`}
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/bpm">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/General/Flow.svg")} />
              </span>
              <span className="menu-text">Processos</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li className="menu-item menu-item-parent">
                  <span className="menu-link">
                    <span className="menu-text">Processos</span>
                  </span>
                </li>
                {hasPermission("instantiate-process-page") && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/bpm/instantiate",
                    )}`}
                  >
                    <NavLink className="menu-link" to="/bpm/instantiate">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Iniciar solicitação</span>
                    </NavLink>
                  </li>
                )}
                {hasPermission("search-tasks-page") && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/bpm/search-solicitation",
                    )}`}
                  >
                    <NavLink
                      className="menu-link"
                      to="/bpm/search-solicitation"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Pesquisar solicitação</span>
                    </NavLink>
                  </li>
                )}
              </ul>
            </div>
          </li>
        )}
        {hasPermission("reports-page") && (
          <li
            className={`menu-item ${getMenuItemActive("/analytics/reports")}`}
          >
            <NavLink className="menu-link" to="/analytics/reports">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Files/File.svg")} />
              </span>
              <span className="menu-text">Relatórios</span>
            </NavLink>
          </li>
        )}
        {hasPermission("business-page") && (
          <li
            className={`menu-item ${getMenuItemActive("/business/my-company")}`}
          >
            <NavLink className="menu-link" to="/business/my-company">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Home/Building.svg")}
                />
              </span>
              <span className="menu-text">Minha empresa</span>
            </NavLink>
          </li>
        )}
        {hasPermission("operational-page") && (
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/operational",
              true,
            )}`}
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/operational">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Devices/Gameboy.svg")}
                />
              </span>
              <span className="menu-text">Operacional</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li className="menu-item menu-item-parent">
                  <span className="menu-link">
                    <span className="menu-text">Operacional</span>
                  </span>
                </li>
                {hasPermission("checklist-events-page") && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/operational/todo",
                    )}`}
                  >
                    <NavLink className="menu-link" to="/operational/todo">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Eventos checklist</span>
                    </NavLink>
                  </li>
                )}
              </ul>
            </div>
          </li>
        )}
        {hasPermission("analytics-page") && (
          <li
            className={`menu-item ${getMenuItemActive("/analytics", false, [
              "/analytics/reports",
            ])}`}
          >
            <a
              className="menu-link"
              href={tmpAnalyticsPageUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Shopping/Chart-pie.svg")}
                />
              </span>
              <span className="menu-text">Analytics</span>
            </a>
          </li>
        )}
        {/*hasPermission("analytics-page") && (
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/analytics",
              true,
            )}`}
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/analytics">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Shopping/Chart-pie.svg")}
                />
              </span>
              <span className="menu-text">Analytics</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu ">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li className="menu-item  menu-item-parent">
                  <span className="menu-link">
                    <span className="menu-text">Analytics</span>
                  </span>
                </li>
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/analytics/payroll",
                  )}`}
                >
                  <NavLink className="menu-link" to="/analytics/payroll">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Folha de pagamento</span>
                  </NavLink>
                </li>
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/analytics/benefits",
                  )}`}
                >
                  <NavLink className="menu-link" to="/analytics/benefits">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Benefícios</span>
                  </NavLink>
                </li>
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/analytics/taxes",
                  )}`}
                >
                  <NavLink className="menu-link" to="/analytics/taxes">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Encargos</span>
                  </NavLink>
                </li>
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/analytics/turnover",
                  )}`}
                >
                  <NavLink className="menu-link" to="/analytics/turnover">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Turnover</span>
                  </NavLink>
                </li>
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/analytics/absenteeism",
                  )}`}
                >
                  <NavLink className="menu-link" to="/analytics/absenteeism">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Absenteísmo</span>
                  </NavLink>
                </li>
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/analytics/absence",
                  )}`}
                >
                  <NavLink className="menu-link" to="/analytics/absence">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Afastamento</span>
                  </NavLink>
                </li>
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/analytics/social",
                  )}`}
                >
                  <NavLink className="menu-link" to="/analytics/social">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Social</span>
                  </NavLink>
                </li>
              </ul>
            </div>
          </li>
                  )*/}
        {hasPermission("settings-page") && (
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/admin",
              true,
            )}`}
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/admin">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/General/Settings-2.svg")}
                />
              </span>
              <span className="menu-text">Painel de controle</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu ">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li className="menu-item menu-item-parent">
                  <span className="menu-link">
                    <span className="menu-text">Painel de controle</span>
                  </span>
                </li>
                {hasPermission("process-instance-adjust-page") && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/admin/process-instance-adjust",
                    )}`}
                  >
                    <NavLink
                      className="menu-link"
                      to="/admin/process-instance-adjust"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        Ajuste instância processo
                      </span>
                    </NavLink>
                  </li>
                )}
                {hasPermission("calendars-page") && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/admin/calendars",
                    )}`}
                  >
                    <NavLink className="menu-link" to="/admin/calendars">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Calendários</span>
                    </NavLink>
                  </li>
                )}
                {hasPermission("flows-page") && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/admin/customers",
                    )}`}
                  >
                    <NavLink className="menu-link" to="/admin/customers">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Clientes</span>
                    </NavLink>
                  </li>
                )}
                {hasPermission("flows-page") && (
                  <li
                    className={`menu-item ${getMenuItemActive("/admin/flows")}`}
                  >
                    <NavLink className="menu-link" to="/admin/flows">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Diagramas</span>
                    </NavLink>
                  </li>
                )}
                {hasPermission("flows-page") && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/admin/companies",
                    )}`}
                  >
                    <NavLink className="menu-link" to="/admin/companies">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Empresas</span>
                    </NavLink>
                  </li>
                )}
                {hasPermission("admin-reports-page") && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/admin/reports-manage",
                    )}`}
                  >
                    <NavLink className="menu-link" to="/admin/reports-manage">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Gestão de relatórios</span>
                    </NavLink>
                  </li>
                )}
                {hasPermission("statistics-page") && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/admin/statistics",
                    )}`}
                  >
                    <NavLink className="menu-link" to="/admin/statistics">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Estatísticas</span>
                    </NavLink>
                  </li>
                )}
                {hasPermission("events-page") && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/admin/events",
                    )}`}
                  >
                    <NavLink className="menu-link" to="/admin/events">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Eventos</span>
                    </NavLink>
                  </li>
                )}
                {hasPermission("holidays-page") && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/admin/holidays",
                    )}`}
                  >
                    <NavLink className="menu-link" to="/admin/holidays">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Feriados</span>
                    </NavLink>
                  </li>
                )}
                {hasPermission("forms-page") && (
                  <li
                    className={`menu-item ${getMenuItemActive("/admin/forms")}`}
                  >
                    <NavLink className="menu-link" to="/admin/forms">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Formulários</span>
                    </NavLink>
                  </li>
                )}
                {hasPermission("groups-page") && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/admin/groups",
                    )}`}
                  >
                    <NavLink className="menu-link" to="/admin/groups">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Grupos</span>
                    </NavLink>
                  </li>
                )}
                {hasPermission("papers-page") && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/admin/papers",
                    )}`}
                  >
                    <NavLink className="menu-link" to="/admin/papers">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Papéis</span>
                    </NavLink>
                  </li>
                )}
                {hasPermission("processes-page") && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/admin/processes",
                    )}`}
                  >
                    <NavLink className="menu-link" to="/admin/processes">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Processos</span>
                    </NavLink>
                  </li>
                )}
                {hasPermission("substitute-users-page") && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/admin/substitutes",
                    )}`}
                  >
                    <NavLink className="menu-link" to="/admin/substitutes">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Substitutos</span>
                    </NavLink>
                  </li>
                )}
                {hasPermission("users-page") && (
                  <li
                    className={`menu-item ${getMenuItemActive("/admin/users")}`}
                  >
                    <NavLink className="menu-link" to="/admin/users">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Usuários</span>
                    </NavLink>
                  </li>
                )}
              </ul>
            </div>
          </li>
        )}
        <li className={`menu-item ${getMenuItemActive("/help")}`}>
          <NavLink className="menu-link" to="/help">
            <span className="svg-icon menu-icon">
              <SupportAgent />
            </span>
            <span className="menu-text">Suporte</span>
          </NavLink>
        </li>
      </ul>
    </>
  );
}
